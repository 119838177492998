import React, { useState, useEffect } from "react";
import './leaderboard.css'
import axios from "axios";
import { Link } from "react-router-dom";
import { getLeaderBoard } from '../api/api'
import Accordion from "./Accordion";
import FooterNav from './FooterNav'
import Topbar from "./TopBar";

const Leaderboard = () => {
  const faqData = [
    {
      question: "Лучшие из лучших по количеству тапов",
      answer: "Лидерборд обновляется автоматически.",
    },
  ];


  const [leaderboardData, setLeaderboardData] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);

  useEffect(() => {
    // Завантажуємо дані з API
    const fetchLeaderboardData = async () => {
      try {
        const data = await getLeaderBoard()
        setLeaderboardData(data || []); // Очікується, що API повертає масив об'єктів
      } catch (error) {
        console.error("Помилка при завантаженні даних:", error);
      }
    };

    fetchLeaderboardData();
  }, []);

  const copyReferral = () => {
    const referralInput = document.getElementById("referralInput");
    referralInput.select();
    referralInput.setSelectionRange(0, 99999); // Для мобільних пристроїв
    document.execCommand("copy");

    // Показуємо сповіщення
    setNotificationVisible(true);
    setTimeout(() => setNotificationVisible(false), 2000);
  };

  return (
    <div className="container-leader">
      <Topbar />

      <div className="content">
        <div className="referral-section">
          <p>Ваша ссылка для приглашения:</p>
          <div className="referral-link">
            <input
              type="text"
              value={`https://t.me/stars_easy_bot?start=${localStorage.getItem('referral')}`}
              id="referralInput"
              readOnly
            />
            <button className="copy-button" onClick={copyReferral}>
              Копировать
            </button>
          </div>
          {notificationVisible && (
            <p id="copy-notification" className="copy-notification">
              Ссылка скопирована!
            </p>
          )}
        </div>
        <div class="stats">
          <p>Ваших активных друзей: <span id="active-friends"> {localStorage.getItem('activeReferrals') || 0}</span></p>
          <p>Ваших друзей:{localStorage.getItem('referrals') || 0} <span id="total-friends">
          </span></p>
        </div>
        <table className="leaderboard">
          <thead>
            <tr>
              <th>Имя</th>
              <th>Очки</th>
              <th>Активных друзей</th>
              <th>Друзей</th>

            </tr>
          </thead>
          <tbody>
            {leaderboardData.length > 0 ? (
              leaderboardData.map((user, index) => (
                <tr key={index}>
                  <td>{user.first_name?.length > 15 ? user?.first_name?.slice(0, 15) : user?.first_name || user.username?.length > 15 ? user?.username?.slice(0, 15) : user?.username || 'No name'}</td>
                  <td>{(user.totalSum).toFixed(3)}</td>
                  <td>{(user.activeReferrals)}</td>
                  <td>{(user.totalReferrals)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Загрузка...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <FooterNav />
    </div>
  );
};

export default Leaderboard;
