import './App.css';
import GamePage from "./pages/game/page/game_page.js";
import WithdrawPage from "./pages/withdrawing/page/withdrawing_page.js";
import FriendsPage from './pages/friends/page/friends_page.js';
import Background from "./components/background.js"
import BottomNavBar from './components/bottom_nav_bar.js';
import AppBar from './components/app_bar.js'
import NotificationProvider from './components/NotificationProvider.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <NotificationProvider>
      <Router>
        <Routes>
          <Route path="/" element={<GamePage />} />
          <Route path="/withdraw" element={<WithdrawPage />} />
          <Route path="/leaderboard" element={<FriendsPage />} />

          {/* <Route path="/withdraw" element={<WithdrawPage />} />
          <Route path="/friends" element={<FriendsPage />} /> */}
        </Routes>
      </Router>
    </NotificationProvider>
  );
}

export default App;
