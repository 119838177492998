function BottomNavBar() {
    return <div className="app-bar">
        <img className="user-image" src={localStorage.getItem('userPhoto') || "/assets/images/fake_image.png"} alt="avatar" />;
        <div className="info">
            <h2>{localStorage.getItem('username') || 'No name'}</h2>
            <h3>Привет 👋 </h3>
        </div>
    </div>
}

export default BottomNavBar;