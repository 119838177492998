import { useState, useEffect } from "react";

// Компонент кнопки
const LinkButton = ({ onTap, value, actionValue, isConfirmVisible, onConfirm }) => {
  return (
    <div className="link-button">
      <img className="leading-icon" src="/assets/icons/ic_bookmark.svg" alt="ic_bookmark" />
      <h4 className="button-text" onClick={onTap}>
        {value}<strong>{actionValue}</strong>
      </h4>
      <div className="button-container">
        {isConfirmVisible ? (
          <button onClick={onConfirm} className="confirm-button">Подтвердить</button>
        ) : (
          <img className="tale-icon" src="/assets/icons/ic_arrow_right.svg" alt="ic_arrow_right" />
        )}
      </div>
    </div>
  );
};

// Основний компонент
const TaskComponent = ({ task, onTaskRemove }) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [confirmedTasks, setConfirmedTasks] = useState([]);

  // Завантаження збережених задач з локального сховища
  useEffect(() => {

    const savedConfirmedTasks = JSON.parse(localStorage.getItem('confirmedTasks')) || [];
    setConfirmedTasks(savedConfirmedTasks);
  }, []);  // Тільки один раз при монтуванні компонента

  // Функція для натискання на задачу
  const handleLinkButtonClick = () => {
    // Зберігаємо в локальному сховищі задачу, яка потребує підтвердження
    const updatedConfirmedTasks = [...confirmedTasks, task.signature];
    setConfirmedTasks(updatedConfirmedTasks);
    localStorage.setItem('confirmedTasks', JSON.stringify(updatedConfirmedTasks));
    // Відкриваємо посилання
    window.open(task.link, '_blank', 'noopener,noreferrer');
    setIsConfirmVisible(true);  // Показуємо кнопку підтвердження
  };

  // Функція для підтвердження
  const handleConfirmClick = (e) => {
    e.stopPropagation(); // Запобігає виконанню події на батьківському елементі

    // Ваш код для підтвердження
    console.log("Задача підтверджена");

    // Видаляємо задачу з масиву
    onTaskRemove(task.signature);

    // Оновлюємо список підтверджених задач
    const updatedConfirmedTasks = confirmedTasks.filter(id => id !== task.signature);
    setConfirmedTasks(updatedConfirmedTasks);
    localStorage.setItem('confirmedTasks', JSON.stringify(updatedConfirmedTasks));
    // Сховати кнопку підтвердження після виконання
    setIsConfirmVisible(false);
  };

  // Перевірка чи задача потребує підтвердження
  const isTaskConfirmed = confirmedTasks.includes(task.signature);

  // Оновлення isConfirmVisible залежно від того, чи в списку підтверджених задач
  useEffect(() => {
    if (isTaskConfirmed) {
      setIsConfirmVisible(true);
    }
  }, [isTaskConfirmed]);  // Відслідковуємо зміни у confirmedTasks

  return (
    <div>
      <LinkButton
        onTap={handleLinkButtonClick}  // Відкриває посилання при натисканні на текст
        value={task.name}
        actionValue={task.actionValue}
        isConfirmVisible={isConfirmVisible}  // Відображає кнопку, якщо задача в списку
        onConfirm={handleConfirmClick}  // Підтвердження
      />
    </div>
  );
};

export default TaskComponent;
