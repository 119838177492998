import WithdrawingButton from '../../../components/withdrawing_button.js';
import '../style/withdrawing_page.css'
import AppBar from '../../../components/app_bar.js'
import BottomNavBar from "../../../components/bottom_nav_bar.js";
import Background from "../../../components/background.js";
import { withdraw } from '../../../api/api.js';
import { useNotification } from "../../../components/NotificationProvider.js";

const LinkButton = ({ onTap, value }) => {
  return (
    <div className="link-button flex items-center p-2 bg-gray-100 rounded-lg shadow-md cursor-pointer hover:bg-gray-200" onClick={onTap}>
      <img className="leading-icon w-6 h-6 mr-2" src="/assets/icons/ic_bookmark.svg" alt="ic_bookmark" />
      <h4 className="button-text flex-1 text-lg font-medium">
        {value}
      </h4>
      <div className="button-container flex items-center">
        <img className="tale-icon w-5 h-5" src="/assets/icons/ic_arrow_right.svg" alt="ic_arrow_right" />
      </div>
    </div>
  );
};


function WithdrawPage() {
  const { addNotification } = useNotification();

  function getArray() {
    let count = +localStorage.getItem('countWithdraw') || 0

    const array = []
    const basePrice = 200
    const step = 200

    for (let i = 0; i <= 2; i++) {
      let amount = basePrice + (i + count) * step
      array.push({ amount, label: `${amount} ⭐️` },
      )
    }

    return array
  }

  const buttons = getArray().map((item, index) => (
    <WithdrawingButton key={index} value={item.amount} onTap={() => withdrawRequest(item.amount)} />
  ));

  async function withdrawRequest(amount) {
    try {
      const res = await withdraw(localStorage.getItem('userId'), amount)

      localStorage.setItem('tapCounter', res.data.tapCounter.toFixed(3))

      addNotification('success', 'Заявка принята, в работе.')
    } catch (error) {
      if (error.response && error.response.data) {
        addNotification('error', error.response.data.message)
        return;
      }
      return
    }
  }

  return <>
    <AppBar />
    <BottomNavBar />
    <Background />
    <div className="withdrawing-page default-page-setting">
      <img className="spring-on-background" src="/assets/icons/ic_spring.svg" alt="ic_spring" />
      <div className="stars-counter top-margin">
        <h5 className="withdrawing-page-title">Ваш баланс</h5>
        <h1>{localStorage.getItem('tapCounter') || 0}</h1>
        <h5>Stars</h5>
      </div>
      <div className="middle-content">
        <h3>Сколько вы хотите вывести?</h3>
        <div className="button-row">
          {buttons}
        </div>
      </div>
      <div className="bottom-content">
        <LinkButton value={'Как заработать друзей'} onTap={() => window.open(process.env.REACT_APP_HOW_GET_FRIENDS_URL, '_blank')} />
        <LinkButton value={'Как добавить ссылку'} onTap={() => window.open(process.env.REACT_APP_HOW_SET_LINK, '_blank')} />
      </div>
    </div>;
  </>
}

export default WithdrawPage;