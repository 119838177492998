import React, { useState, useEffect } from "react";
import { getLeaderBoard } from '../../../api/api.js'
import AppBar from '../../../components/app_bar.js'
import BottomNavBar from "../../../components/bottom_nav_bar.js";
import Background from "../../../components/background.js";
import '../style/friends_page.css'
import { useNotification } from "../../../components/NotificationProvider";

function FriendsPage() {
  const { addNotification } = useNotification();

  const [leaderboardData, setLeaderboardData] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const copyReferral = () => {
    const referralText = document.querySelector('.link')?.textContent; // Отримуємо текст посилання
    if (!referralText) return;

    navigator.clipboard.writeText(referralText)
      .then(() => {
        addNotification('success', 'Ссылка скопирована в буфер обмена');
        setTimeout(() => setNotificationVisible(false), 2000);
      })
      .catch(err => console.error("Помилка копіювання:", err));
  };

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const data = await getLeaderBoard().catch((err) => addNotification('error', 'Что-то не так с сервером. Мы уже чиним. Админ на связи.'))
        setLeaderboardData(data || []);
      } catch (error) {
        console.error("Помилка при завантаженні даних:", error);
      }
    };

    fetchLeaderboardData();
  }, []);


  return <>
    <AppBar />
    <BottomNavBar />
    <Background />
    <div className="friends-page default-page-setting">
      <div className="top-content top-margin">
        <h5 className="friends-page-title">Зарабатывай с друзьями</h5>
        <h5>Приглашай друзей, чтобы заработать больше</h5>
      </div>
      <div className="link-row" id="referralInput">
        <div className="link">{`${process.env.REACT_APP_BOT_LINK}?start=${localStorage.getItem('referral')}`}</div>
        <div onClick={copyReferral} style={{ cursor: "pointer" }}>
          <img src="/assets/icons/ic_copy.svg" className="navbar-icon" alt="ic_copy" />
        </div>
      </div>
      <div>
        <h5 className="friends-page-main-text">Ваших друзей: {localStorage.getItem('referrals') || 0}</h5>
        <h5 className="friends-page-main-text">Ваших активных друзей:{(+localStorage.getItem('activeReferrals') || 0) + (+localStorage.getItem('virtualFriend') || 0)}</h5>
        <p className="friends-page-main-text-new ">Активный друг  — это друг, у которого есть 200 звезд.</p>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Имя</th>
                <th>Очки</th>
                <th>Активных друзей</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.length > 0 ? (
                leaderboardData.map((user, index) => (
                  <tr key={index}>
                    <td>{user.first_name?.length > 15 ? user?.first_name?.slice(0, 15) : user?.first_name || user.username?.length > 15 ? user?.username?.slice(0, 15) : user?.username || 'No name'}</td>
                    <td>{(user.totalSum).toFixed(3)}</td>
                    <td>{(user.activeReferrals)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">Загрузка...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

      </div>
    </div>

  </>;
}

export default FriendsPage;