import axios from 'axios';

const API = process.env.REACT_APP_API_URL;

// Функція для отримання токена з localStorage
const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

// Функція для загального налаштування заголовків
const getHeaders = () => {
  const token = getAuthToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getTelegramUser = async () => {
  const response = await axios.get(`${API}/get-user-info`, {
    headers: getHeaders(),  // Додаємо токен до заголовків
  });
  return response.data;
};

export const loginUser = async (initData) => {
  const response = await axios.post(`${API}/login`, {
    initData
  });
  return response.data;
};


export const updateTapCounter = async (userId, tapCounter) => {
  const response = await axios.post(
    `${API}/taps`,
    { userId, tapCounter },
    { headers: getHeaders() }  // Додаємо токен до заголовків
  );
  return response.data;
};

export const getLeaderBoard = async () => {
  const response = await axios.get(`${API}/get-top-users`, {
    headers: getHeaders(),  // Додаємо токен до заголовків
  });
  return response.data;
};

export const checker = async (userId, count) => {
  const response = await axios.post(
    `${API}/check-ref`,
    { userId, count },
    { headers: getHeaders() }  // Додаємо токен до заголовків
  );
  return response;
};

export const withdraw = async (userId, count, link = null) => {
  const response = await axios.post(
    `${API}/set-link`,
    { userId, link, count },
    { headers: getHeaders() }  // Додаємо токен до заголовків
  );
  return response;
};

export const confirmTask = async (userId, signature) => {
  const response = await axios.post(
    `${API}/confirm-task`,
    { userId, signature },
    { headers: getHeaders() }  // Додаємо токен до заголовків
  );
  return response;
};
