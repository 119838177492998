import { Link } from "react-router-dom";

function BottomNavBar() {
    return <div className="bottom-nav-bar">
        <Link to="/" className="navbar-item">
            <img src="/assets/icons/ic_joystick.svg" className="navbar-icon" alt="ic_joystick" />
            <p>Играть</p>
        </Link>
        <Link to="/leaderboard" className="navbar-item">
            <img src="/assets/icons/ic_handshake.svg" className="navbar-icon" alt="ic_handshake" />
            <p>Друзья</p>
        </Link>
        <Link to="/withdraw" className="navbar-item">
            <img src="/assets/icons/ic_money.svg" className="navbar-icon" alt="ic_money" />
            <p>Вывод</p>
        </Link>

    </div>
}

export default BottomNavBar;