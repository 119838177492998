const cleanPercentage = (percentage) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0;
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
  };
  
  const Circle = ({ colour, percentage }) => {
    const r = 153.5;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - percentage) * circ) / 100; 
    return (
      <circle
        r={r}
        cx={156.5}
        cy={156.5}
        fill="transparent"
        stroke={strokePct !== circ ? colour : ""}
        strokeWidth={"6px"}
        strokeDasharray={circ}
        strokeDashoffset={percentage ? strokePct : 0}
      ></circle>
    );
  };
  
  const Pie = ({ percentage, colour }) => {
    const pct = cleanPercentage(percentage);
    return (
      <svg width={313} height={313}>
        <g transform={`rotate(-90 ${"156.5 156.5"})`}>
          <Circle colour="#6B6B6B" />
          <Circle colour={colour} percentage={pct} />
        </g>
      </svg>
    );
  };

export default Pie;